@import url(https://fonts.googleapis.com/css?family=Rubik:400,500,600,700&display=swap);
@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Inter&display=swap);
/* Colours */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body,
input,
button {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
.pfTitle {
  display: block;
  font-size: 24px;
  text-align: center;
}
.customToast {
  /* !important to override some weird bug of styles not applying */
  font-family: "Roboto", sans-serif !important;
  background: white !important;
  color: black !important;
  padding: 0 12px !important;
  border-radius: 4px !important;
}
.referralCount {
  font-size: 4rem;
  color: #fc6472;
  margin-bottom: 0.5rem;
}
.shareTags {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: -1;
}
.Column {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
}
.WideColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.App {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 15px;
  overflow: hidden;
}
.Input {
  width: 50%;
  height: 43px;
  border-radius: 4px 0 0 4px;
  border-style: solid;
  border-color: #fc6472;
  padding: 10px 10px;
  -webkit-transition: 0.15s;
  transition: 0.15s;
}
.Input::-webkit-input-placeholder {
  font-size: 1rem;
  opacity: 0.5;
}
.Input::-moz-placeholder {
  font-size: 1rem;
  opacity: 0.5;
}
.Input::-ms-input-placeholder {
  font-size: 1rem;
  opacity: 0.5;
}
.Input::placeholder {
  font-size: 1rem;
  opacity: 0.5;
}
.Input:disabled {
  color: #333;
  opacity: 1;
}
.Input:hover {
  border-color: rgba(35, 199, 177, 0.8);
  -webkit-transition: 0.15s;
  transition: 0.15s;
}
.Input:focus {
  outline: none;
  border-color: rgba(35, 199, 177, 0.8);
}
.Button {
  width: 150px;
  height: 43px;
  border: 2px solid #fc6472;
  border-radius: 0 4px 4px 0;
  background-color: #fc6472;
  color: #fff;
  font-size: 1rem;
  padding: 10px 0px;
  -webkit-transition: 0.15s;
  transition: 0.15s;
}
.Button:hover {
  border-color: rgba(35, 199, 177, 0.8);
  background-color: #23c7b1;
  cursor: pointer;
  -webkit-transition: 0.15s;
  transition: 0.15s;
}
.Button:focus {
  outline: none;
}
.Button:disabled {
  border-color: #b0b0b0;
  background-color: #c4c4c4;
}
.Button:disabled:hover {
  cursor: auto;
  cursor: initial;
}
.TagVectorTopLeft {
  left: 12%;
  top: 13%;
  position: absolute;
  z-index: -1;
}
.TagVectorBottomLeft {
  left: 10%;
  bottom: 25%;
  position: absolute;
  z-index: -1;
}
.TagVectorTopRight {
  right: 5%;
  top: 10%;
  position: absolute;
  z-index: -1;
}
.TagVectorBottomRight {
  right: 7%;
  bottom: 20%;
  position: absolute;
  z-index: -1;
}
.VectorTop {
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
.Row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.HowItWorksContainer .title {
  margin: 80px 0 40px;
}
.HowItWorksContainer .description {
  margin-bottom: 40px;
}
.HowItWorksContainer .HowItWorks {
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 150px;
}
.HowItWorksContainer .HowItWorks .la {
  font-size: 3rem;
  color: rgba(196, 196, 196, 0.5);
}
.HowItWorksContainer .HowItWorks > div {
  padding: 16px 40px;
  box-shadow: 0px 2px 4px rgba(196, 196, 196, 0.25);
}

.HowItWorksContainer .HowItWorks div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HowItWorksContainer .HowItWorks div > .number {
  font-size: 5rem;
  color: rgba(196, 196, 196, 0.5);
  margin-bottom: -1.5rem;
}
.HowItWorksContainer .HowItWorks div > .text {
  font-size: 1.15rem;
}

@media (max-width: 500px) {
  .HowItWorksContainer .HowItWorks {
    flex-direction: column;
  }
  .HowItWorksContainer .HowItWorks div {
    margin: 1.5rem 0;
  }
  .HowItWorksContainer .HowItWorks > div {
    width: 75%;
  }
  .HowItWorksContainer .HowItWorks div > .number {
    font-size: 5rem;
    color: rgba(128, 128, 128, 0.3);
    margin-bottom: -2rem;
  }
  .HowItWorksContainer .HowItWorks div > .text {
    font-size: 1.5rem;
  }

  .HowItWorksContainer .HowItWorks i {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
.inputrow {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.ArchiveItem {
  box-shadow: 3px 3px 4px 0px rgba(243, 176, 164, 0.8);
  margin-bottom: 15px;
  padding: 10px;
  background: #fff;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
.ArchiveItem:hover {
  -webkit-transition: 0.35s;
  transition: 0.35s;
  box-shadow: 5px 5px 4px 0px rgba(35, 199, 177, 0.8);
}
.ArchiveDate {
  font-size: 0.8rem;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.7);
}
.ArchiveTitle {
  font-size: 1.15rem;
  margin-bottom: 0.5rem;
}
.CuratedBy {
  position: absolute;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  padding: 20px;
  background: #f4b2a6;
  opacity: 0.9;
  width: 100%;
  bottom: 0;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
.Footer {
  margin-top: 250px;
  width: 100vw;
}
.VectorBottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  max-height: 200px;
  -webkit-transition: 0.37s;
  transition: 0.37s;
}
.VectorBottomLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  max-height: 200px;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
.VectorGeoBottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  max-height: 200px;
  -webkit-transition: 0.37s;
  transition: 0.37s;
}
.RefGeoBottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  max-height: 400px;
  -webkit-transition: 0.37s;
  transition: 0.37s;
}
@media (max-width: 800px) {
  .RefGeoBottomRight {
    max-height: 250px;
  }
}
@media (max-width: 500px) {
  .RefGeoBottomRight {
    max-height: 150px;
  }
}
.VectorGeoBottomLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  max-height: 200px;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
.LoadMoreButton {
  cursor: pointer;
  text-decoration: none;
  font-size: 1.25rem;
  color: #f4b2a6;
  background: none;
  border: none;
}
.LoadMoreButton:focus {
  color: #f4b2a6;
}
.LoadMoreButton:hover {
  color: #23c7b1;
}
.SideVector {
  position: absolute;
  right: 0;
  margin-top: 30%;
  max-height: 1000px;
  z-index: -10;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
.BigText {
  font-size: 68px;
  font-weight: 600;
  margin-bottom: 30px;
}
.SubText {
  font-size: 20px;
  line-height: 33px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 30px;
}
.LandingBigText {
  font-size: 38px;
  font-weight: 600;
  line-height: 38px;
}
.LandingSubText {
  font-size: 22px;
  font-weight: normal;
  line-height: 38px;
  margin-top: 20px;
  text-align: left;
}
.SmallTitleText {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #c4c4c4;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.NormalTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 40px;
}
.NormalText {
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
}
.TooltipText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #c4c4c4;
  margin-top: 5px;
}
.redBackground {
  background: rgba(243, 176, 164, 0.2);
  border-radius: 4px;
  width: 100%;
  padding: 35px;
}
.shareBg {
  width: 100%;
  padding: 35px;
  margin-top: 110px;
}
.icon {
  width: 80px;
  cursor: pointer;
}
.upperlink {
  display: flex;
  justify-content: center;
}
.progressBar {
  position: absolute;
  margin-top: 5px;
  width: 100%;
  z-index: -1;
}
.referralFooter .CuratedBy {
  color: #e5e5e5;
  padding: 20px;
  background: transparent;
  z-index: 10;
}
.referralFooter .CuratedBy:hover {
  color: #fc6472;
}
.referralFooter .VectorGeoBottomLeft {
  display: none;
}
@media (max-width: 1200px) {
  .SideVector {
    margin-top: 35%;
  }
  .BigText {
    text-align: center;
  }
}
@media (min-width: 800px) and (max-width: 1200px) {
  .BigText {
    text-align: center;
  }
  .SubText {
    text-align: center;
  }
}
@media (max-width: 800px) {
  .LandingBigText {
    font-size: 32px;
  }
  .LandingSubText {
    font-size: 22px;
  }
  .SideVector {
    margin-top: 50%;
    max-height: 500px;
    -webkit-transition: 0.35s;
    transition: 0.35s;
  }
  .VectorTop {
    left: -80px;
  }
  .bgText {
    font-size: 48px;
  }
  .VectorBottomRight {
    max-height: 150px;
    -webkit-transition: 0.37s;
    transition: 0.37s;
  }
  .VectorBottomLeft {
    max-height: 150px;
    -webkit-transition: 0.35s;
    transition: 0.35s;
  }
  .BigText {
    font-size: 32px;
    text-align: left;
  }
  .SubText {
    font-size: 16px;
    text-align: left;
  }
  .VectorGeoBottomRight {
    max-height: 150px;
    -webkit-transition: 0.37s;
    transition: 0.37s;
  }
  .VectorGeoBottomLeft {
    max-height: 150px;
    -webkit-transition: 0.35s;
    transition: 0.35s;
  }
}
@media (max-width: 500px) {
  .pfTitle {
    font-weight: 600;
  }
  .SideVector {
    top: 0;
    max-height: 400px;
    -webkit-transition: 0.35s;
    transition: 0.35s;
  }
  .LandingBigText {
    font-size: 32px;
    margin-top: -50px;
  }
  .desktop {
    display: none;
  }
  .VectorTop {
    display: none;
  }
  .Button {
    width: 100%;
    margin-top: 15px;
    border-radius: 4px;
  }
  .Input {
    width: 100%;
    margin-right: 0;
    border-radius: 4px;
  }
  .VectorBottomRight {
    max-height: 125px;
    -webkit-transition: 0.37s;
    transition: 0.37s;
  }
  .VectorBottomLeft {
    max-height: 125px;
    -webkit-transition: 0.35s;
    transition: 0.35s;
  }
  .VectorTopRight {
    top: 5%;
  }
  .VectorTopLeft {
    top: 3%;
  }
  .VectorBottomRight {
    display: none;
  }
  .VectorBottomLeft {
    display: none;
  }
  .BigText {
    font-size: 40px;
  }
  .desktop {
    display: none;
  }
  .inputrow {
    flex-direction: column;
  }
  .VectorGeoBottomRight {
    max-height: 125px;
    -webkit-transition: 0.37s;
    transition: 0.37s;
  }
  .VectorGeoBottomLeft {
    max-height: 125px;
    -webkit-transition: 0.35s;
    transition: 0.35s;
  }
  .TagVectorBottomLeft {
    display: none;
  }
  .TagVectorBottomRight {
    display: none;
  }
  .TagVectorTopLeft {
    top: 3%;
  }
  .TagVectorTopRight {
    top: 4%;
  }
}
@media (max-width: 300px) {
  .VectorTop {
    left: -180px;
    -webkit-transition: 0.15s;
    transition: 0.15s;
  }
}

